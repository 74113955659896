import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchZoomUsers(ctx, data) {
      return useJwt.getZoomUsers(data).then(response => response);
    },
    synchronizeZoomUsers(ctx, data) {
      return useJwt.synchronizeZoomUsers(data).then(response => response);
    },
    createAccount(ctx, data) {
      return useJwt.createZoomUser(data).then(response => response);
    },
    updateAccount(ctx, data) {
      return useJwt.updateZoomUser(data).then(response => response);
    },
    deleteAccount(ctx, id) {
      return useJwt.deleteZoomUser(id).then(response => response);
    },
  },
};
